<template>
  <div class="details">
    <!--<div v-for="(detail, i) in details" :key="i + 1">
      <h2 v-html="detail.title"></h2>
      <p class="desc">{{ detail.subtitle }}</p>
      <h2 v-html="detail.description"></h2>
      <details-gallery v-if="detail.gallery" :gallery="detail.gallery" />
    </div>-->
    <h2>{{$t('campaignDetail.What you need todo')}}</h2>
    <div class="detail-inner">
      <h2>
        <img v-lazy="`${$config.IMG_HOST}/20x20/31.png`" alt="" class="img-fluid"/>
        {{$t('campaignDetail.Post')}} {{ campaign.posts }} {{$t('campaignDetail.story on your Instagram following the instructions below')}}.</h2>
      <!-- <p class="desc">Please add the following text: "Do you have more than 500 followers? Get free products or cash using the app"</p> -->
       <p
          v-if="campaign.is_html_description === 1"
          class="desc"
          v-html="campaign.description"
        ></p>
        <!--Himanshu Rehani-->
        <p v-if="campaign.is_html_description === 0" class="desc">
          {{ campaign.description }}
        </p>
        <!--Himanshu Rehani-->
    </div>
    <h2>{{$t('campaignDetail.Material offered by brand')}}</h2>
    <div class="row products">
      <div class="col-md-6 col-6">
        <div class="image">
          <img
            v-lazy="`${$config.IMG_HOST}/orig/${campaign.image}`"
            alt=""
            width="auto"
            height="640px"
          />
        </div>
      </div>
      <!--<div class="col-md-3 col-6">
        <div class="image">
          <img
            v-lazy="`${$config.IMG_HOST}/296x346/${campaign.image}`"
            alt=""
            class="img-fluid"
            width="100%"
            height="336px"
          />
        </div>
      </div>-->
    </div>
    <!-- <a download="145.jpg" href="https://images.hostify.one/orig/145.jpg" >Download this picture of a flamingo.</a> -->
    <button
      @click="onSubmitDownload(`${$config.IMG_HOST}/orig/${campaign.image}`)"
      class="btn btn-primary medium"
    >
      {{$t('campaignDetail.Download')}}
    </button>
    <h2>{{$t('campaignDetail.Follow the steps')}}</h2>
    <div class="detail-inner">
      <ul>
        <li>{{$t('campaignDetail.Switch to an instagram creator account in order to access the insights of your post')}}.</li>
        <li>{{$t('campaignDetail.Post 1 photo/video story on your Instagram and tag the business following the provided instructions')}}.</li>
        <li>{{$t('campaignDetail.Add paid partnership label to your post')}}.</li>
        <li>{{$t('campaignDetail.Wait 20h after posting and take screenshot of the content and insights')}}.</li>
        <li>{{$t('campaignDetail.Return to the deal and upload the proof of the post and the insights to finish the collaboration')}}.</li>
      </ul>
  
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 0,
    };
  },
  components: {
    DetailsGallery: () =>
      import(
        /* webpackChunkName: "detailsGallery" */ "./partials/DetailsGallery.vue"
      ),
  },
  created() {
    this.id = parseInt(this.$route.params.id);
  },
  props: {
    campaign: Object,
    /*details: Object,*/
    processing: Boolean,
  },
  methods: {
    async onSubmitDownload(url) {
      const fileName = url.split('/').pop();
      const docUrl = document.createElement('a');
      docUrl.href = url+"?download";
      docUrl.setAttribute('target', '_blank');
      document.body.appendChild(docUrl);
      docUrl.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  h2 {
    font-size: rem(18px);
    font-weight: 500;
    color: var(--textPrimary);
    font-family: $secondary-font;
    text-decoration: underline;
    margin: 20px 0;
    ::v-deep p {
      margin: 0;
    }
  }
  .detail-inner {
    background: #fff;
    margin: 20px 0px;
    padding: 5px 10px;
    h2{
      text-decoration: none;
    }
    ul
    {
      padding: 0px;
      margin: 10px 0px 0px;
      li{
         font-size: 14px;
         margin-bottom: 5px;
         list-style: decimal inside;
      }

    }
  }
  .products {
  margin: rem(18px) 0;
  .image {
    border-radius: 28px;
    overflow: hidden;
    @include flex(center, center);
  }
  @media screen and (max-width: 767px) {
    > div {
      margin-bottom: rem(22px);
    }
  }
}
}
</style>
